<template>
    <div v-if="!breakpoints.isSmaller('sm')" class="relative bg-gray-50 h-[100vh] overflow-y-hidden">
        <Suspense>
            <slot></slot>
            <template #fallback>
                <div class="flex flex-col items-center justify-center h-screen">
                    <img src="/assets/wobby-loading-transparent-bg.gif" width="250" />
                    <p class="font-fakt-medium text-3xl mb-4">Loading your Workspace...</p>
                </div>
            </template>
        </Suspense>
    </div>
    <div v-else>
        <div class="p-8">
            <img class="-ml-5 -mb-2 h-20" src="/assets/wobby-logo/logo_purple.svg" />
            <div class="my-5">
                <h1 class="font-fakt-medium text-lg text-gray-800">Oops! Wobby platform isn't optimized for mobile yet.
                </h1>
                <p class="mt-1 text-gray-800">Please try accessing it from a desktop or laptop for the
                    best
                    experience.<br> Thanks for your understanding!</p>
            </div>
            <a class=" bg-gray-50 hover:bg-gray-100 ring-1 p-2 text-gray-500 ring-wobby-purple rounded-lg"
                href="https://wobby.ai">Back to Homepage</a>
        </div>
        <footer class="mt-5 bg-gray-50 w-full flex py-5 px-8">
            <div class="flex w-full rounded-md">
                <div class="flex text-sm text-gray-400 justify-end items-center gap-2">
                    <a class=" hover:text-gray-800" href="https://wobby.ai?utm_source=app" target="_blank">© Copyright
                        Wobby
                        BV {{ new Date().getFullYear() }}</a>
                </div>

                <div class="flex-grow"></div>

                <div class="flex text-sm  text-gray-400 justify-end items-center gap-2">
                    <a class="hover:text-gray-800" href="https://www.wobby.ai/terms-of-use" target="_blank">Terms
                        of
                        Use</a>
                    <a class="hover:text-gray-800" href="https://wobby.ai/privacy-policy/" target="_blank">Privacy
                        Policy</a>
                    <!-- <a target="_blank">Cookie Policy</a>-->
                </div>
            </div>
        </footer>

    </div>

</template>

<script setup lang="ts">
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'

const breakpoints = useBreakpoints(breakpointsTailwind)

</script>